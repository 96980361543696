import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"

import Discussion from "../components/Discussion"
import Layout from "../components/Layout"
import Link from "../components/Link"
import PostNavigation from "../components/PostNavigation"
import PostHeader from "../components/PostHeader"
import Posts from "../components/Posts"

import contentStyles from "../scss/components/PostContent.module.scss"
import postStyles from "../scss/components/Post.module.scss"

const PostTemplate = ({ data, pageContext }) => {
  const { markdownRemark: post, allTagsJson, allMarkdownRemark: related } = data
  const { nodes: tags } = allTagsJson
  const { next, prev } = pageContext

  const { title } = post.frontmatter
  const { excerpt, html } = post

  const mainTag = tags[0]

  return (
    <Layout>
      <Helmet>
        <title>{title} • Elliot Wright</title>
        <meta name="description" content={excerpt} />
      </Helmet>

      <PostHeader post={post} tags={tags} />

      <div className={postStyles.container}>
        <div className={contentStyles.container} dangerouslySetInnerHTML={{ __html: html }} />
      </div>

      <Discussion post={post} />

      {mainTag && related && (
        <Posts
          title={<>More posts in <Link to={`/tag/${mainTag.slug}`}>{mainTag.name}</Link></>}
          posts={related.edges}
        />
      )}

      <PostNavigation prev={prev} next={next} />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!, $tags: [String!], $mainTag: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      excerpt
      html
      timeToRead
      frontmatter {
        title
        tags
        date
        formattedDate: date(formatString: "MMMM Do, YYYY")
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 960, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    
    allTagsJson(filter: { slug: { in: $tags } }) {
      nodes {
        slug
        name
      }
    }
    
    allMarkdownRemark(
      filter: {fields: {collection: {eq: "posts"}}, frontmatter: {slug: {ne: $slug}, tags: {eq: $mainTag}}},
      limit: 5,
      sort: {order: DESC, fields: frontmatter___date}
    ) {
      edges {
        node {
          id
          frontmatter {
            slug
            title
            date
            formattedDate: date(formatString: "MMMM Do, YYYY")
          }
        }
      }
    }
  }
`

export default PostTemplate
