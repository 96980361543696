import _ from "lodash"
import React, { Fragment } from "react"
import Img from "gatsby-image"

import Link from "./Link"

import styles from "../scss/components/PostHeader.module.scss"

const Tags = ({ tags }) => {
  if (!tags || !tags.length) {
    return <></>
  }

  return (
    <div className={styles.tags}>
      In{" "}
      {tags.map((tag, index) => (
        <Fragment key={tag.slug}>
          <Link to={`/tag/${tag.slug}`}>{tag.name}</Link>
          {index !== tags.length - 1 && (
            <>{", "}</>
          )}
        </Fragment>
      ))}
    </div>
  )
}

const PostHeader = ({ post, tags }) => {
  const featuredImage = _.get(post, "frontmatter.featuredImage.childImageSharp.fluid")

  return (
    <>
      <header className={styles.container}>
        <h1 className={styles.title}>
          {post.frontmatter.title}
        </h1>

        <div className={styles.meta}>
          <Tags tags={tags} />
          <div className={styles.date}>
            <time dateTime={post.frontmatter.date}>
              {post.frontmatter.formattedDate}
            </time>
          </div>
          <div className={styles.readingTime}>
            {post.timeToRead} Min Read
          </div>
        </div>
      </header>

      {featuredImage && (
        <div className={styles.featureImage}>
          <Img fluid={featuredImage} />
        </div>
      )}
    </>
  )
}

export default PostHeader
