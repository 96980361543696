import React from "react"

import PostLink from "./PostLink"
import styles from "../scss/components/Posts.module.scss"

const Post = ({ post }) => {
  const { frontmatter } = post

  return (
    <PostLink post={post}>
      <article className={styles.item}>
        <span>{frontmatter.title}</span>
        <time dateTime={frontmatter.date}>
          {frontmatter.formattedDate}
        </time>
      </article>
    </PostLink>
  )
}

const Posts = ({ title, posts }) => {
  return (
    <section className={styles.container}>
      <h4 className={styles.title}>{title || "Posts"}</h4>

      {posts.map(post => (
        <Post key={post.node.id} post={post.node} />
      ))}
    </section>
  )
}

export default Posts
