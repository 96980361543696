import React from "react"

import PostLink from "./PostLink"
import styles from "../scss/components/PostNavigation.module.scss"

const PostNavigation = ({ prev, next }) => {
  return (
    <>
      {prev && (
        <div className={styles.prev}>
          <PostLink post={prev}>
            Previous Post
          </PostLink>
        </div>
      )}

      {next && (
        <div className={styles.next}>
          <PostLink post={next}>
            Next Post
          </PostLink>
        </div>
      )}
    </>
  )
}

export default PostNavigation
