import React from "react"

import Link from "../components/Link"

const PostLink = ({ post, children }) => {
  const slug = post.frontmatter.slug
  const date = new Date(post.frontmatter.date)

  const year = date.getUTCFullYear()
  const month = `${date.getUTCMonth()+1}`.padStart(2, "0")
  const day = `${date.getUTCDate()}`.padStart(2, "0")

  return (
    <Link to={`/${year}/${month}/${day}/${slug}`}>
      {children}
    </Link>
  )
}

export default PostLink
